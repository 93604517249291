import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import Image from "gatsby-image"
import ReadLink from "../components/read-link"

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        image {
          sharp: childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      body
    }
  }
`

const PostTemplate = ({ data: { mdx: post } }) => {
  console.log(post.frontmatter.image.sharp.fluid)
  return (
    <Layout>
      <div
        css={css`
          margin-left: 30px;
          width: 75%;

          p {
            font-family: "PT Serif", serif;
            padding-left: 5px;
          }
        `}
      >
        <h1
          css={css`
            font-size: 2.5em;
            font-family: "Oxygen", sans-serif;
            width: calc(100%-20px);
            background-color: skyblue;
            border-radius: 5px;
            color: white;
            padding: 20px;
          @media (min-width: 420px) {
            grid-template-columns: 1fr 3fr;
          }
          `}
        >
          {post.frontmatter.title}
        </h1>
        <ReadLink to="/">&larr; back to all posts</ReadLink>
        <Image
          fluid={post.frontmatter.image.sharp.fluid}
          css={css`
            object-fit: cover;
            margin-bottom: 30px;
            width: 100%;
            min-height: 300px;
            border-radius: 10px;
            @media (min-width: 1000px) {
            }
          `}
        />

        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export default PostTemplate
